import { ProductTag } from '../constants/products';

export function modifyFiltersForLastChance({ filters, alwaysShowLastChance }) {
  let sizeFiltersEmpty = true;
  const { aggregationFilter } = { ...filters };

  for (const key in aggregationFilter) {
    if (
      key.startsWith('size_') &&
      Array.isArray(aggregationFilter[key]) &&
      aggregationFilter[key].length > 0
    ) {
      sizeFiltersEmpty = false;
    }
  }

  if (
    // always show last chance flag from builder is false
    !alwaysShowLastChance &&
    // no size filters chosen
    sizeFiltersEmpty
  ) {
    // if last chance tag 6002 not already excluded
    if (!filters.excludeTagIds.includes(ProductTag.lastChance)) {
      // exclude last chance tag
      filters.excludeTagIds = [ProductTag.lastChance, ...filters.excludeTagIds];
    }
  } else if (!sizeFiltersEmpty || alwaysShowLastChance) {
    // if last chance tag 6002 is included
    if (filters.excludeTagIds.includes(ProductTag.lastChance)) {
      // remove last chance tag on the excluded tags
      filters.excludeTagIds = filters.excludeTagIds.filter(
        (tagId) => tagId !== ProductTag.lastChance
      );
    }
  }
}
